import { Injectable, signal, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {} from '@awo-care/shared-core';
import { environment } from '@awo-care/shared-auth';
export interface Language {
  code: string;
  name: string;
}

const LANGUAGE_KEY = 'preferredLanguage';
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private document = inject(DOCUMENT);

  availableLanguages: Language[] = [
    { code: 'de', name: 'Deutsch' },
    { code: 'en', name: 'English' },
  ];

  currentLanguage = signal<Language>(this.getInitialLanguage());

  constructor() {
    if (environment.production) this.checkAndRedirect();
  }

  private getInitialLanguage(): Language {
    const storedLang = localStorage.getItem(LANGUAGE_KEY);
    if (storedLang) {
      const lang = this.availableLanguages.find((l) => l.code === storedLang);
      if (lang) return lang;
    }

    const currentLocale = this.document.documentElement.lang;
    const lang = this.availableLanguages.find((l) => l.code === currentLocale);

    return lang || this.availableLanguages[0];
  }

  private checkAndRedirect() {
    const storedLang = localStorage.getItem(LANGUAGE_KEY);
    if (!storedLang) {
      localStorage.setItem(LANGUAGE_KEY, this.currentLanguage().code);
      return;
    }

    const currentPath = window.location.pathname;
    const currentLangMatch = currentPath.match(/^\/(de|en)/);
    const currentLangCode = currentLangMatch ? currentLangMatch[1] : null;

    if (currentLangCode !== storedLang) {
      const newPath = `/${storedLang}${
        currentPath.startsWith('/de') || currentPath.startsWith('/en')
          ? currentPath.substring(3)
          : currentPath
      }`;
      const queryString = window.location.search;
      window.location.replace(`${newPath}${queryString}`);
    }
  }

  setLanguage(language: Language) {
    if (language.code === this.currentLanguage().code) return;

    localStorage.setItem(LANGUAGE_KEY, language.code);

    const currentPath = window.location.pathname;
    const newPath = `/${language.code}${
      currentPath.startsWith('/de') || currentPath.startsWith('/en')
        ? currentPath.substring(3)
        : currentPath
    }`;
    const queryString = window.location.search;
    window.location.href = `${newPath}${queryString}`;
  }

  getLanguages(): Language[] {
    return this.availableLanguages;
  }
}
